import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useState, useCallback, useEffect } from "react";
import { Card, CardContent, Typography, Grid, Fab, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { InputParameters } from '../types/inputParameters';

interface CustomTextCardProperties {
  onInputChange: Function;
  onClick: Function;
  isSelected: boolean;
  inputParams: Partial<InputParameters>;
}

function CustomTextCard(props: CustomTextCardProperties) {
  // Initialize the state with an array of custom text objects
  const [customText, setCustomText] = useState<{ category: string; description: string; }[]>([]);

  // Synchronize the state with inputParams whenever inputParams change
  useEffect(() => {
    if (props.inputParams?.customText?.custom_texts) {
      const transformedText = props.inputParams.customText.custom_texts.map((item: Record<string, any>) => ({
        category: item.category || "",
        description: item.description || "",
      }));
      setCustomText(transformedText);
    }
  }, [props.inputParams.customText]);

  // Add a new custom text entry
  const addCustomText = useCallback(() => {
    setCustomText((prevCustomText) => [
      ...prevCustomText,
      {
        category: "",
        description: "",
      },
    ]);
  }, []);

  // Remove a custom text entry
  const removeCustomText = useCallback((index: any) => {
    setCustomText((prevCustomText) =>
      prevCustomText.filter((_, i) => i !== index)
    );
  }, []);

  // Handle changes to category or description
  const handleChange = () => {
    // Update the parent component's state through onInputChange
    props.onInputChange({ customText: { custom_texts: customText } });
  };

  const handleLocalChange = (index: number, field: string, value: any) => {
    setCustomText((prevCustomText) => {
      const newCustomText = [...prevCustomText];
      newCustomText[index] = {
        ...newCustomText[index],
        [field]: value,
      };
      return newCustomText;
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card
        sx={{
          marginTop: 2,
          marginBottom: -2.6,
          minWidth: 100,
          boxShadow: props.isSelected
            ? 'inset 0px 0px 0px 1px var(--twd_aqua)'
            : 'none',
        }}
        onClick={(event) => props.onClick(event)}
      >
        <CardContent sx={{ '&.MuiCardContent-root': { paddingBottom: 1 } }}>
          <Typography variant="h6" gutterBottom>
            <Grid container spacing={0}>
              <Grid sm>Custom Text</Grid>
              <Grid sm={"auto"}>
                <Fab
                  sx={{ zIndex: 1, width: '36px', height: '36px' }}
                  color="secondary"
                  variant="circular"
                  aria-label="remove"
                  size="small"
                  onClick={() => removeCustomText(customText.length - 1)}
                >
                  <RemoveIcon />
                </Fab>
              </Grid>
              <Grid sm={"auto"} paddingLeft={1}>
                <Fab
                  sx={{ zIndex: 1, width: '36px', height: '36px' }}
                  color="secondary"
                  variant="circular"
                  aria-label="add"
                  onClick={addCustomText}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Typography>
          <Typography>
            {customText.map((item, index) => (
              <Grid container spacing={1} key={index}>
                <Grid item sm={5}>
                  <FormControl fullWidth size="small" margin="normal">
                    <InputLabel id={`category-label-${index}`}>Category</InputLabel>
                    <Select
                      labelId={`category-label-${index}`}
                      value={item.category || ""}
                      label="Category"
                      onChange={(e) =>
                        handleLocalChange(index, "category", e.target.value)
                      }
                    >
                      <MenuItem value={'Detailed Design Drawings'}>
                        Detailed Design Drawings
                      </MenuItem>
                      <MenuItem value={'Dimensions'}>Dimensions</MenuItem>
                      <MenuItem value={'Weight'}>Weight</MenuItem>
                      <MenuItem value={'Design'}>Design</MenuItem>
                      <MenuItem value={'Materials'}>Materials</MenuItem>
                      <MenuItem value={'Fabrication'}>Fabrication</MenuItem>
                      <MenuItem value={'Welding'}>Welding</MenuItem>
                      <MenuItem value={'Weld Inspection'}>
                        Weld Inspection
                      </MenuItem>
                      <MenuItem value={'Coating'}>Coating</MenuItem>
                      <MenuItem value={'Assembly'}>Assembly</MenuItem>
                      <MenuItem value={'Functional Testing'}>
                        Functional Testing
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm>
                  <TextField
                    size="small"
                    id={`description-${index}`}
                    margin="normal"
                    label="Custom text"
                    variant="outlined"
                    value={customText[index].description || ""}
                    onChange={(e) => handleLocalChange(index, "description", e.target.value)}
                    onBlur={() => handleChange()}
                    fullWidth
                  />
                </Grid>
              </Grid>

            ))}
          </Typography>
        </CardContent>
      </Card>
    </LocalizationProvider>
  );
}
export default CustomTextCard;
