import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


function EmptyInfo() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Bolt Pre-Tension
        </Typography>
        <Typography variant="body2">
          Specify tensioning of bolts.
          <ul>
           <li> Hand tight if no pre-tension is required. </li>
           <li> Pre-tension when calculated. </li>
          </ul> 
          If you have different values per bolt group in your design, choose the most commen one.
          <ul>
           <li>Still mention the pretension value in kN on your drawing.
           </li>
           <li>When Torque is specified, make sure the lubricant is specified too! </li>
           <li> In general, we do not provide torque values. </li>
          </ul> 
        </Typography>
        <CardMedia>
          <img
            src="BoltPretension.png"
            alt="Bolt Pretension"
            style={{ maxWidth: '100%', cursor: 'pointer' }}
            onClick={handleOpen}
          />
        </CardMedia>
        <Typography variant="body2">
          <a href={process.env.REACT_APP_BOLTS_SHAREPOINT} target="_blank" rel="noopener noreferrer">Learn more about bolt tensioning</a>
        </Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: '90%',
              maxHeight: '90%',
              overflow: 'auto',
              borderRadius: 2,
              border: '2px solid var(--twd_aqua, aqua)',
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src="BoltPretension.png"
              alt="Bolt Pretension"
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}

export default EmptyInfo;
