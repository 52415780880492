import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { InputParameters } from '../types/inputParameters';

interface FabricationCardProperties {
  onInputChange: Function;
  onClick: Function;
  isSelected: boolean;
  inputParams: Partial<InputParameters>;
}

function FabricationCard(props: FabricationCardProperties) {
  const [linearDimensions, setLinearDimensions] = React.useState<string>(props.inputParams?.fabrication?.welding_tolerances_linear_angles || '');
  const [straightnessClass, setStraightnessClass] = React.useState<string>(props.inputParams?.fabrication?.welding_tolerances_straightness || '');
  const [machiningTolerance2768_1, setMachiningTolerance2768_1] = React.useState<string>(props.inputParams?.fabrication?.machining_tolerances_iso_2768_1 || '');
  const [machiningTolerance2768_2, setMachiningTolerance2768_2] = React.useState<string>(props.inputParams?.fabrication?.machining_tolerances_iso_2768_2 || '');

  // Sync state with external props.inputParams changes using useEffect
  React.useEffect(() => {
    if (props.inputParams?.fabrication?.welding_tolerances_linear_angles !== undefined) {
      setLinearDimensions(props.inputParams.fabrication.welding_tolerances_linear_angles);
    }
    if (props.inputParams?.fabrication?.welding_tolerances_straightness !== undefined) {
      setStraightnessClass(props.inputParams.fabrication.welding_tolerances_straightness);
    }
    if (props.inputParams?.fabrication?.machining_tolerances_iso_2768_1 !== undefined) {
      setMachiningTolerance2768_1(props.inputParams.fabrication.machining_tolerances_iso_2768_1);
    }
    if (props.inputParams?.fabrication?.machining_tolerances_iso_2768_2 !== undefined) {
      setMachiningTolerance2768_2(props.inputParams.fabrication.machining_tolerances_iso_2768_2);
    }
  }, [props.inputParams.fabrication]);

// Generic handler for input changes
const handleFabricationInputChange = (key: string, newValue: string) => {
  switch (key) {
    case 'welding_tolerances_linear_angles':
      setLinearDimensions(newValue);
      break;
    case 'welding_tolerances_straightness':
      setStraightnessClass(newValue);
      break;
    case 'machining_tolerances_iso_2768_1':
      setMachiningTolerance2768_1(newValue);
      break;
    case 'machining_tolerances_iso_2768_2':
      setMachiningTolerance2768_2(newValue);
      break;
    default:
      console.warn(`Unknown key: ${key}`);
      return;
  }

  // Update fabrication object
  props.onInputChange({
    fabrication: {
      ...props.inputParams?.fabrication, // Preserve existing properties
      [key]: newValue, // Update the specific property
    },
  });
};


  return (
    <Card
      sx={{
        marginBottom: 1,
        minHeight: 240,
        boxShadow: props.isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none',
      }}
      onClick={(event) => props.onClick(event)}
      >
      <CardContent component="div" sx={{ '&.MuiCardContent-root': { paddingBottom: 0 } }}>
        <Typography variant="h6" gutterBottom>
          Fabrication Tolerances
        </Typography>
        <Typography variant="body2">
          <Grid pt={1} container spacing={1} alignItems={'center'}>
            <Grid item sm={7}>
              Linear and angular dimension tolerances for welded assemblies ISO-13920
            </Grid>
            <Grid item sm>
              <ToggleButtonGroup
                fullWidth
                size="small"
                color="primary"
                exclusive
                value={linearDimensions}
                onChange={(_, newValue) => {
                  handleFabricationInputChange('welding_tolerances_linear_angles', newValue);
                  }}
              >
                <ToggleButton value="A">A</ToggleButton>
                <ToggleButton value="B">B</ToggleButton>
                <ToggleButton value="C">C</ToggleButton>
                <ToggleButton value="D">D</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid pt={1} container spacing={1} alignItems={'center'}>
            <Grid item sm={7}>
              Straightness, flatness parallelism for welded assemblies ISO-13920
            </Grid>
            <Grid item sm>
              <ToggleButtonGroup
                fullWidth
                size="small"
                color="primary"
                exclusive
                value={straightnessClass}
                onChange={(_, newValue) => {
                  handleFabricationInputChange('welding_tolerances_straightness', newValue);
                  }}
              >
                <ToggleButton value="E">E</ToggleButton>
                <ToggleButton value="F">F</ToggleButton>
                <ToggleButton value="G">G</ToggleButton>
                <ToggleButton value="H">H</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid pt={1} container spacing={1} alignItems={'center'}>
            <Grid item sm={7}>
              Linear and angular dimension tolerances for machining ISO-2768-1
            </Grid>
            <Grid item sm>
              <ToggleButtonGroup
                fullWidth
                size="small"
                color="primary"
                exclusive
                value={machiningTolerance2768_1}
                onChange={(_, newValue) => {
                  handleFabricationInputChange('machining_tolerances_iso_2768_1', newValue);
                  }}
              >
                <ToggleButton value="f">f</ToggleButton>
                <ToggleButton value="m">m</ToggleButton>
                <ToggleButton value="c">c</ToggleButton>
                <ToggleButton value="v">v</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid pt={1} container spacing={1} alignItems={'center'}>
            <Grid item sm={7}>
              Straightness, flatness and parallelism tolerances for machining ISO-2768-2
            </Grid>
            <Grid item sm>
              <ToggleButtonGroup
                fullWidth
                size="small"
                color="primary"
                exclusive
                value={machiningTolerance2768_2}
                onChange={(_, newValue) => {
                  handleFabricationInputChange('machining_tolerances_iso_2768_2', newValue);
                  }}
              >
                <ToggleButton value="H">H</ToggleButton>
                <ToggleButton value="K">K</ToggleButton>
                <ToggleButton value="L">L</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default FabricationCard;
