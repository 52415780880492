import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


function EmptyInfo() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Pin Material
        </Typography>
        <Typography variant="body2">
        <div>
  <p>When selecting a pin material, consider its strength and suitability for the design. Here's a simple guide:</p>
  <ul>
    <li><strong>42CrMo4</strong> ~850 MPa. <strong>Benefits:</strong> High strength. <strong>Limitations:</strong> Sensitive to high temperatures.</li>
    <li><strong>34CrNiMo6</strong> ~930 MPa. <strong>Benefits:</strong> Excellent strength and wear resistance. <strong>Limitations:</strong> Requires heat treatment.</li>
    <li><strong>30CrNiMo8</strong> ~800 MPa. <strong>Benefits:</strong> High impact resistance. <strong>Limitations:</strong> Needs heat treatment.</li>
    <li><strong>S235</strong> ~235 MPa. <strong>Benefits:</strong> Economical, easy to weld. <strong>Limitations:</strong> Lower strength.</li>
    <li><strong>S355</strong> ~355 MPa. <strong>Benefits:</strong> Strong, versatile. <strong>Limitations:</strong> Lower corrosion resistance.</li>
    <li><strong>C2205 Duplex</strong> ~450 MPa. <strong>Benefits:</strong> Excellent corrosion resistance. <strong>Limitations:</strong> High cost.</li>
  </ul>
</div>

        </Typography>
        <CardMedia>
          <img
            src="PinMaterial.png"
            alt="Bolt Pretension"
            style={{ maxWidth: '100%', cursor: 'pointer' }}
            onClick={handleOpen}
          />
        </CardMedia>
        <Typography variant="body2">
          <a href={process.env.REACT_APP_GENERAL_NOTES_WORKSHOP_SHAREPOINT} target="_blank" rel="noopener noreferrer">Learn more about pin materials</a>
        </Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: '90%',
              maxHeight: '90%',
              overflow: 'auto',
              borderRadius: 2,
              border: '2px solid var(--twd_aqua, aqua)',
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src="PinMaterial.png"
              alt="Bolt Pretension"
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}

export default EmptyInfo;
