import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ClientModal from './ClientModal';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { InputParameters } from '../types/inputParameters';
import clientDatabase from '../database/ClientDatabase';

interface ProjectCardProperties {
  onInputChange: Function;
  onClick: Function;
  isSelected: boolean;
  inputParams: Partial<InputParameters>;
}

function ProjectCard(props: ProjectCardProperties) {
  const [drawingNumber, setDrawingNumber] = useState('');
  const [title, setTitle] = useState('');
  const [projectName, setProjectName] = useState('');
  const [clientName, setClientName] = useState('');
  const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState<Boolean>(false);

  useEffect(() => {
    document.getElementById('downloadButton')?.addEventListener('click', () => {setIsDownloadButtonClicked(true);});
  }, []);

  useEffect(() => {
    // Synchronize state with external inputParams changes
    if (props.inputParams?.titleBlock?.drawing_number !== undefined) {
      setDrawingNumber(props.inputParams?.titleBlock?.drawing_number);
    }
    if (props.inputParams?.titleBlock?.title !== undefined) {
      setTitle(props.inputParams?.titleBlock?.title);
    }
    if (props.inputParams?.titleBlock?.project_name !== undefined) {
      setProjectName(props.inputParams?.titleBlock?.project_name);
    }
    if (props.inputParams?.titleBlock?.client?.name !== undefined) {
      setClientName(props.inputParams?.titleBlock?.client.name);
    }
  }, [props.inputParams?.titleBlock]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleSaveClient = (data: any) => {
    props.onInputChange({
      titleBlock: {
        ...props.inputParams?.titleBlock,
        client: data,
      },
    });
    console.log('Projectcard received client data:', data);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClientChange = (event: SelectChangeEvent<string>) => {
    const clientName = event.target.value;
    setClientName(clientName);
    const selectedClient = clientDatabase.find(client => client.name === clientName);
    if (selectedClient) {
      props.onInputChange({
        titleBlock: {
          ...props.inputParams?.titleBlock,
          client: selectedClient
        }
      });
    }
    console.log('Client selected:', clientName);
  };

  return (
    <Card 
      sx={{ 
        marginTop: 2, 
        minWidth: 100, 
        height: 280, 
        boxShadow: props.isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none'
      }} 
      onClick={(event) => props.onClick(event)}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Project
        </Typography>
        <TextField
          fullWidth
          size="small"
          margin="normal"
          id="drawingNumber"
          label="Drawing Number"
          variant="outlined"
          value={drawingNumber}
          onChange={(e) => {
            setDrawingNumber(e.target.value);
            props.onInputChange({
              titleBlock: {
                ...props.inputParams?.titleBlock,
                drawing_number: e.target.value
              }
            });
          }}
          onBlur={(e) => props.onInputChange({
            titleBlock: {
              ...props.inputParams.titleBlock,
              drawing_number: e.target.value
            }
          })}
          error={isDownloadButtonClicked && drawingNumber === ''}
        />
        <TextField
          fullWidth
          size="small"
          margin="normal"
          id="title"
          label="Title"
          variant="outlined"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            props.onInputChange({
              titleBlock: {
                ...props.inputParams?.titleBlock,
                title: e.target.value
              }
            });
          }}
          onBlur={(e) => props.onInputChange({
            titleBlock: {
              ...props.inputParams.titleBlock,
              title: e.target.value
            }
          })}
          error={isDownloadButtonClicked && title === ''}
        />
        <TextField
          fullWidth
          size="small"
          margin="normal"
          id="projectName"
          label="Project Name"
          variant="outlined"
          value={projectName}
          onChange={(e) => {
            setProjectName(e.target.value);
            props.onInputChange({
              titleBlock: {
                ...props.inputParams?.titleBlock,
                project_name: e.target.value
              }
            });
          }}
          onBlur={(e) => props.onInputChange({
            titleBlock: {
              ...props.inputParams.titleBlock,
              project_name: e.target.value
            }
          })}
          error={isDownloadButtonClicked && projectName === ''}
        />
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel id="client-label">Client</InputLabel>
              <Select
                labelId="client-label"
                id="clientName"
                name="clientName"
                value={clientName}
                label="Client"
                onChange={handleClientChange}
              >
                {clientDatabase.map((client, index) => (
                  <MenuItem key={index} value={client.name}>{client.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs="auto" marginTop={1}>
            <Button variant="outlined" color="primary" onClick={handleOpenModal} >
              +
            </Button>
          </Grid>
        </Grid>
        <ClientModal open={isModalOpen} onClose={handleCloseModal} onClientDataSave={handleSaveClient} inputParams={props.inputParams} />
      </CardContent>
    </Card>
  );
}

export default ProjectCard;