import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function DesignInfo() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Design
        </Typography>
        <Typography variant="body2">
        <div>
          <p><strong>Design Temperature:</strong></p>
          <ul>
            <li><strong>-10°C</strong> – Suitable for colder environments. <em>Benefit:</em> Ensures structural integrity in sub-zero conditions. <em>Limitation:</em> May require additional material testing or adjustments.</li>
            <li><strong>0°C</strong> – For moderate environments. <em>Benefit:</em> Easier to meet material requirements. <em>Limitation:</em> Not suitable for cold climates.</li>
          </ul>
          
          <p><strong>Design Code:</strong></p>
          <ul>
            <li><strong>DNV C-101</strong> – Applicable to offshore structures. <em>Benefit:</em> Covers general structural design. <em>Limitation:</em> May not address specific operational conditions.</li>
            <li><strong>DNV ST-0378</strong> – Tailored for offshore crane and lifting appliances. <em>Benefit:</em> Specific to dynamic and load-bearing equipment. <em>Limitation:</em> More specialized, less general applicability.</li>
          </ul>
          
          <strong>Design Description:</strong><br></br>
          Provide a short description of the purpose of the design here.
        </div>
        </Typography>
        <CardMedia>
          <img
            src="DesignTemperature.png"
            alt="Bolt Pretension"
            style={{ maxWidth: '100%', cursor: 'pointer' }}
            onClick={handleOpen}
          />
        </CardMedia>
        <Typography variant="body2">
        <strong>Useful links: </strong>
          <li><a href={process.env.REACT_APP_GENERAL_NOTES_WORKSHOP_SHAREPOINT} target="_blank" rel="noopener noreferrer">Learn more about design temperature and material codes</a></li>
          <li><a href={process.env.REACT_APP_GENERAL_NOTES_LIBRARY} target="_blank" rel="noopener noreferrer">CSV files for use in MTO Tool</a></li>
        </Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: '90%',
              maxHeight: '90%',
              overflow: 'auto',
              borderRadius: 2,
              border: '2px solid var(--twd_aqua, aqua)',
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src="DesignTemperature.png"
              alt="Bolt Pretension"
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}

export default DesignInfo;
