import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { InputParameters } from '../types/inputParameters';

interface BearingCardProperties {
  onInputChange: Function;
  onClick: Function;
  isSelected: boolean;
  inputParams: Partial<InputParameters>;
}
const bearingMaterialsList = [
  { label: 'Aluminium Bronze (250 MPa)'},
  { label: 'FRLP (200 MPa)'},
  { label: 'HDPE (20 MPa)'},
];

function BearingCard(props: BearingCardProperties) {

  const [bearingEnabled, setBearingEnabled] = useState(false);

  useEffect(() => {
    // Synchronize state with inputParams
    if (props.inputParams?.material?.bearing_material) {
      setBearingEnabled(true); // Auto-enable card if `bearing_material` is set
    }
  }, [props.inputParams?.material?.bearing_material]);

  const handleCheckboxChange = () => {
    setBearingEnabled((prev) => {
      const newBearingEnabled = !prev; // Toggle the bearing enabled state

      if (!newBearingEnabled) {
        // Reset `bearing_material` when enabling the checkbox
        props.onInputChange({
          material: {
            ...props.inputParams.material,
            bearing_material: '', // Reset bearing_material to empty
          },
        });
      }

      return newBearingEnabled;
    });
  };

  const handleMaterialChange = (newValue: { label: string } | null ) => {
    const materialUpdate = {
      material: {
        ...props.inputParams.material,
        bearing_material: newValue?.label || '', // Update only `bearing_material`
      },
    };

    props.onInputChange(materialUpdate); // Call parent handler with updated material
  };

  return (
    <Card
      sx={{
        marginTop: 2,
        boxShadow: props.isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none',
      }}
      onClick={(event) => props.onClick(event)}
      >
      <CardContent
        component="div"
        sx={{ paddingTop: 1, '&.MuiCardContent-root': { paddingBottom: 1 } }}
      >
        <Typography variant="h6">
          <Grid container spacing={0} alignItems="center">
            <Grid item sm>
              Bearing Material
            </Grid>
            <Grid item sm="auto">
              <Checkbox checked={bearingEnabled} onChange={handleCheckboxChange} />
            </Grid>
          </Grid>
        </Typography>
        {bearingEnabled && (
          <Typography variant="body2">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small" margin="normal">
                <Autocomplete
                  fullWidth
                  size="small"
                  options={bearingMaterialsList}
                  value={bearingMaterialsList.find(
                    (option) => option.label === props.inputParams?.material?.bearing_material
                  ) || null} // Set value directly from props
                  renderInput={(params) => (
                    <TextField {...params} label="Bearing Material" />
                  )}
                  onChange={(_, newValue) => {
                    handleMaterialChange(newValue);
                  }}
                />
              </FormControl>
            </Box>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default BearingCard;
