import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { InputParameters } from '../types/inputParameters';
import { Autocomplete, TextField } from '@mui/material';

interface AccessCardProperties {
  onInputChange: Function;
  onClick: Function;
  isSelected: boolean;
  inputParams: Partial<InputParameters>;
}
const AccessMaterialsList = [
  { label: 'SECONDARY' },
  { label: 'PRIMARY' },
  { label: 'SPECIAL' },
];

function AccessCard(props: AccessCardProperties) {

  const [accessEnabled, setAccessEnabled] = useState(false);

  useEffect(() => {
    // Synchronize state with inputParams
    if (props.inputParams?.material?.access_steel) {
      setAccessEnabled(true); // Auto-enable card if `bearing_material` is set
    }
  }, [props.inputParams?.material?.access_steel]);

  const handleCheckboxChange = () => {
    setAccessEnabled((prev) => {
      const newAccessEnabled = !prev; // Toggle the access enabled state

      if (!newAccessEnabled) {
        // Reset `access_material` when enabling the checkbox
        props.onInputChange({
          material: {
            ...props.inputParams.material,
            access_steel: '', // Reset access material to empty
          },
        });
      }
      return newAccessEnabled;
    });
  };


  const handleMaterialChange = (newValue: { label: string } | null) => {
    const materialUpdate = {
      material: {
        ...props.inputParams.material,
        access_steel: newValue?.label || '', // Update only `access_steel`
      },
    };

    props.onInputChange(materialUpdate); // Call parent handler with updated material
  };

  return (
    <Card
      sx={{
        marginTop: 2,
        boxShadow: props.isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none',
        }}
        onClick={(event) => props.onClick(event)}
      >
      <CardContent
        component="div"
        sx={{ paddingTop: 1, '&.MuiCardContent-root': { paddingBottom: 1 } }}
      >
        <Typography variant="h6">
          <Grid container spacing={0} alignItems="center">
            <Grid item sm>
              Access Material
            </Grid>
            <Grid item sm="auto">
              <Checkbox checked={accessEnabled} onChange={handleCheckboxChange} />
            </Grid>
          </Grid>
        </Typography>
        {accessEnabled && (
          <Typography variant="body2">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small" margin="normal">
                <Autocomplete
                  fullWidth
                  size="small"
                  options={AccessMaterialsList}
                  value={AccessMaterialsList.find(
                    (option) => option.label === props.inputParams?.material?.access_steel
                  ) || null} // Set value directly from props
                  renderInput={(params) => (
                    <TextField {...params} label="Access Material" />
                  )}
                    onChange={(_, newValue) => {
                    handleMaterialChange(newValue);
                  }}                
                  />
              </FormControl>
            </Box>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default AccessCard;
