import { InputGeneralNotes } from '../types/inputParameters';

export async function fetchGeneralNotesPdf(
  inputRevs: InputGeneralNotes,
  reportName: string
): Promise<void> {
  const apiUrl: string | undefined = process.env.REACT_APP_API_URL;
  const apiSecret: string | undefined = process.env.REACT_APP_API_SECRET;
  if (!(apiSecret && apiUrl)) {
    console.error('ENVIRONMENTAL VARIABLES MISSING');
    return;
  }

  try {
    const response = await fetch(`${apiUrl}/general-notes/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiSecret,
      },
      body: JSON.stringify(inputRevs),
    });

    console.info(inputRevs);   

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jsonResponse = await response.json();
    console.info(jsonResponse);

    if (!jsonResponse.data.general_notes || typeof jsonResponse.data.general_notes !== 'string') {
      throw new Error('Invalid Base64 string');
    }

    const binaryString = window.atob(jsonResponse.data.general_notes);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: 'application/pdf' });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = reportName;
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 100);
  } catch (e) {
    console.log('Error in fetchGeneralNotesPdf:', e);
    throw e;
  }
}
