import { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import { InputParameters } from '../types/inputParameters';

interface NdtCardProperties {
  onInputChange: Function;
  onClick: Function;
  isSelected: boolean;
  inputParams: Partial<InputParameters>;
}

const ndtPercentageOptions = Array.from({ length: 9 }, (_, i) => ({ label: (20 + i * 10).toString() })); // Generate options from 20 to 100 in steps of 10

function NdtCard(props: NdtCardProperties) {
  const [ndtPercentage, setNdtPercentage] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Synchronize state with external inputParams changes
    if (props.inputParams?.table2?.ndt_percentage !== undefined) {
      setNdtPercentage(props.inputParams.table2.ndt_percentage);
    }
  }, [props.inputParams?.table2]);

  const handlePercentageChange = (newValue: { label: string } | null) => {
    const numericValue = newValue ? parseInt(newValue.label, 10) : undefined;
    setNdtPercentage(numericValue);
    props.onInputChange({
      table2: {
        ...props.inputParams?.table2,
        ndt_percentage: numericValue, // Update NDT-B in table2
      },
    });
  };

  return (
    <Card
      sx={{ marginTop: 2, boxShadow: props.isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none' }}
      onClick={(event) => props.onClick(event)}
    >
      <CardContent component="div" sx={{ paddingTop: 1, '&.MuiCardContent-root': { paddingBottom: 1 } }}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item sm={5}>
            <Typography variant="h6" fontStyle={'revert'}>
              Non-destructive Testing
            </Typography>
          </Grid>
          <Grid item sm>
            <Typography variant="body2">
              <TextField
                size="small"
                margin="normal"
                id="ndtA"
                label="NDT-A"
                variant="outlined"
                value="100"
                disabled
              />
            </Typography>
          </Grid>
          <Grid item sm>
            <Typography variant="body2">
              <Autocomplete
                fullWidth
                size="small"
                options={ndtPercentageOptions}
                value={
                  ndtPercentageOptions.find(
                    (option) => option.label === (ndtPercentage ? ndtPercentage.toString() : '')
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="NDT-B" margin="normal" />
                )}
                onChange={(_, newValue) => {
                  handlePercentageChange(newValue);
                }}
              />
            </Typography>
          </Grid>
          <Grid item sm>
            <Typography variant="body2">
              <TextField
                size="small"
                margin="normal"
                id="ndtC"
                label="NDT-C"
                variant="outlined"
                value="5"
                disabled
              />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default NdtCard;
