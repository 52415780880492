import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { fetchGeneralNotesPdf } from "../services/swegApi";
import { InputGeneralNotes } from "../types/inputParameters";

interface DownloadBtnProps {
  inputRevs: InputGeneralNotes;
};
interface AlertProps{
  open: boolean; 
  alertType: "success" | "error"; 
  message: string;
};

export default function DownloadBtn( props : DownloadBtnProps) { 
   const [alert, setAlert] = React.useState<AlertProps>({
    open: false,
    alertType: "success",
    message: "",
  });

  const handleClick = async () => {
    try {
      const pdfName = `${props.inputRevs.revisions[props.inputRevs.revisions.length - 1]?.titleBlock.drawing_number}, ${props.inputRevs.revisions[props.inputRevs.revisions.length - 1]?.titleBlock.title}.pdf`;
      await fetchGeneralNotesPdf(props.inputRevs, pdfName);
      setAlert({ open: true, alertType: "success", message: "Download completed!" });
    } catch (error) {
      setAlert({ open: true, alertType: "error", message: `Download failed: ${(error as Error).message}` });
    }
  };

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ ...alert, open: false });
  };

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleClick}>
        PDF
      </Button>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleAlertClose} severity={alert.alertType} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
