import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { InputParameters } from '../types/inputParameters';

interface BoltCardProperties {
  onInputChange: Function;
  onClick: Function;
  isSelected: boolean;
  inputParams: Partial<InputParameters>;
}

function BoltCard(props: BoltCardProperties) {
  const [boltPretension, setBoltPretension] = useState('');
  const [pretensionType, setPretensionType] = useState('');
  const [boltEnabled, setBoltEnabled] = useState(false);
  const [isTextFieldDisabled, setIsTextFieldDisabled] = useState(false);

  useEffect(() => {
    // Synchronize state with inputParams
    if (props.inputParams?.assembly?.bolts_pretension || props.inputParams?.assembly?.bolts_method) {
      setBoltPretension(props.inputParams?.assembly?.bolts_pretension || '');
      setPretensionType(props.inputParams?.assembly?.bolts_method || '');
      setBoltEnabled(true);
      setIsTextFieldDisabled(props.inputParams?.assembly?.bolts_method === 'Hand Tight');
    }
  }, [props.inputParams.assembly]);

  const handleCheckboxChange = () => {
    setBoltEnabled((prev) => {
      const newBoltEnabled = !prev; // Toggle the bolt enabled state

      if (!newBoltEnabled) {
        setBoltPretension(''); // Clear local state
        setPretensionType(''); // Clear local state
        // Reset `bolt_pretension` when enabling the checkbox
        props.onInputChange({
          assembly: {
            ...props.inputParams.assembly,
            bolts_pretension: '', // Reset bolts_pretension to empty
            bolts_method: '', // Reset bolts_method to empty
          },
        });
      }
      return newBoltEnabled;
    });
  };

  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setBoltPretension(value);
    props.onInputChange({
      assembly: {
        ...props.inputParams.assembly,
        bolts_pretension: value,
      },
    });
  };

  const handleBtnInputChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setPretensionType(selectedValue);
  
    // Disable text field and reset boltPretension if "Hand Tight" is selected
    if (selectedValue === 'Hand Tight') {
      setBoltPretension(''); // Clear local state
      props.onInputChange({
        assembly: {
          ...props.inputParams.assembly,
          bolts_pretension: '', // Reset bolts_pretension in the parent state
          bolts_method: selectedValue,
        },
      });
    } else {
      props.onInputChange({
        assembly: {
          ...props.inputParams.assembly,
          bolts_method: selectedValue,
        },
      });
    }
  
    setIsTextFieldDisabled(selectedValue === 'Hand Tight');
  };
  

  return (
    <Card
      sx={{
        marginTop: 2,
        boxShadow: props.isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none',
      }}
      onClick={(event) => props.onClick(event)}
      >
      <CardContent
        component="div"
        sx={{ paddingTop: 1, '&.MuiCardContent-root': { paddingBottom: 1 } }}
      >
        <Typography variant="h6">
          <Grid container spacing={0} alignItems="center">
            <Grid item sm>
              Bolt Pre-tension
            </Grid>
            <Grid item sm="auto">
              <Checkbox checked={boltEnabled} onChange={handleCheckboxChange} />
            </Grid>
          </Grid>
        </Typography>
        {boltEnabled && (
          <Typography variant="body2">
            <Grid container spacing={1}>
              <Grid item sm>
                <FormControl fullWidth size="small" margin="normal">
                  <InputLabel id="pretension-type-label">Type</InputLabel>
                  <Select
                    labelId="pretension-type-label"
                    value={pretensionType}
                    label="Type"
                    onChange={handleBtnInputChange}
                  >
                    <MenuItem value="Hand Tight">Hand Tight</MenuItem>
                    <MenuItem value="Pre-tension">Pre-Tension</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm>
                <TextField
                  size="small"
                  margin="normal"
                  id="boltPretension"
                  label="Pretension %"
                  variant="outlined"
                  value={boltPretension}
                  onChange={handleTextInputChange}
                  disabled={isTextFieldDisabled} // Disable text field based on pretensionType
                />
              </Grid>
            </Grid>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default BoltCard;
