import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useState, useCallback, useEffect } from "react";
import { Card, CardContent, Typography, Grid, Fab, TextField } from '@mui/material';
import { DateField } from '@mui/x-date-pickers/DateField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { InputParameters } from '../types/inputParameters';
import dayjs from 'dayjs';

interface RevisionCardProps {
  onInputChange: Function;
  inputParams: Partial<InputParameters>;
  onRevisionChange: Function
}
interface revisionProps {
  date: string; 
  author: string; 
  pr: string; 
  app: string;
}

function RevisionCard(props: RevisionCardProps) {
  const [revisions, setRevisions] = useState<revisionProps[]>([
    { date: dayjs().format(), 
      author: "", 
      pr: "", 
      app: "" 
    }
  ]);
  const [activeRevisionIndex, setActiveRevisionIndex] = useState(0);

  // Synchronize the state with inputParams whenever inputParams change
  useEffect(() => {
    if (props.inputParams?.titleBlock?.revisions) {
      const transformedRevisions = props.inputParams.titleBlock.revisions.map((item: Record<string, any>) => ({
        date: item.date || "",
        author: item.author || "",
        pr: item.pr || "",
        app: item.app || "",
      }));
      setRevisions(transformedRevisions);
      setActiveRevisionIndex(transformedRevisions.length - 1); // Set the active revision index to the last one
    }
  }, [props.inputParams.titleBlock?.revisions]);

  const addRevision = useCallback(() => {
    const newRevision = { date: dayjs().format(), author: "", pr: "", app: "" };

    setRevisions((prevRevisions) => {
      const updatedRevisions = [...prevRevisions, newRevision];
      setActiveRevisionIndex(updatedRevisions.length - 1); // Make the new revision the active one
      if (props.onRevisionChange) {
        props.onRevisionChange('add', updatedRevisions);
      }
      return updatedRevisions;
    });
  }, [props.inputParams.titleBlock?.revisions]);

  const removeRevision = useCallback((index: number) => {
    if (index === activeRevisionIndex) {
      // If the active revision is being removed, make the previous revision active
      setActiveRevisionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
    setRevisions((prevRevisions) =>
      prevRevisions.filter((_, i) => i !== index)
    );
  }, [activeRevisionIndex]);

  const handleChange = (index: number, field: string, value: any) => {
    setRevisions((prevRevisions) => {
      const newRevisions = [...prevRevisions];
      newRevisions[index] = {
        ...newRevisions[index],
        [field]: value,
      };
      return newRevisions;
    });
  };

  const handleBlur = () => {
    // Update only the revision field inside the titleBlock when input loses focus
    props.onInputChange({
      titleBlock: {
        ...props.inputParams?.titleBlock, // Spread existing titleBlock properties
        revisions: revisions, // Only update the revision field
      },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card sx={{ marginTop: 2, minWidth: 100 }}>
        <CardContent sx={{ '&.MuiCardContent-root': { paddingBottom: 1 } }}>
          <Typography variant="h6" gutterBottom>
            <Grid container spacing={0}>
              <Grid sm>Revisions</Grid>
              <Grid sm={"auto"}>
                <Fab
                  sx={{ zIndex: 1, width: '36px', height: '36px' }}
                  color="secondary"
                  aria-label="remove"
                  size="small"
                  onClick={() => removeRevision(revisions.length - 1)}
                  disabled={revisions.length <= 1}
                >
                  <RemoveIcon />
                </Fab>
              </Grid>
              <Grid sm={"auto"} paddingLeft={1}>
                <Fab
                  sx={{ zIndex: 1, width: '36px', height: '36px' }}
                  color="secondary"
                  aria-label="add"
                  size="small"
                  onClick={addRevision}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Typography>
          <Typography>
            {revisions.map((revisions, index) => (
              <Grid container spacing={1} alignItems={"center"} key={index}>
                <Grid item xs={1}>
                  <Typography pl={1} align="right" variant="button">
                    {index}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <DateField
                    size="small"
                    margin="normal"
                    label="Date"
                    variant="outlined"
                    format="DD-MM-YYYY"
                    value={revisions.date ? dayjs(revisions.date) : null} // Convert string to dayjs object
                    onChange={(date: any) => handleChange(index, "date", date)}
                    onBlur={handleBlur}
                    disabled={index !== activeRevisionIndex} // Only editable if it's the active revision
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    size="small"
                    margin="normal"
                    label="Author"
                    variant="outlined"
                    value={revisions.author}
                    onChange={(e) =>
                      handleChange(index, "author", e.target.value)
                    }
                    onBlur={handleBlur}
                    disabled={index !== activeRevisionIndex} // Only editable if it's the active revision
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    size="small"
                    margin="normal"
                    label="PR"
                    variant="outlined"
                    value={revisions.pr}
                    onChange={(e) =>
                      handleChange(index, "pr", e.target.value)
                    }
                    onBlur={handleBlur}
                    disabled={index !== activeRevisionIndex} // Only editable if it's the active revision
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    size="small"
                    margin="normal"
                    label="App"
                    variant="outlined"
                    value={revisions.app}
                    onChange={(e) =>
                      handleChange(index, "app", e.target.value)
                    }
                    onBlur={handleBlur}
                    disabled={index !== activeRevisionIndex} // Only editable if it's the active revision
                  />
                </Grid>
              </Grid>
            ))}
          </Typography>
        </CardContent>
      </Card>
    </LocalizationProvider>
  );
}

export default RevisionCard;
