import { useState, useEffect } from 'react';
import './App.css';
import { Box, ThemeProvider, Grid, Container, FormControl } from "@mui/material";
import { twdTheme } from "./styles/CustomMaterialTheme";
import { styled } from "@mui/material/styles";
import ProjectCard from './components/ProjectCard';
import DesignCard from './components/DesignCard';
import FabricationCard from './components/FabricationCard';
import InfoCard from './components/InfoCard';
import BoltCard from './components/BoltCard';
import PinCard from './components/PinCard';
import DownloadBtn from './components/DownloadBtn';
import BearingCard from './components/BearingCard'; 
import AccessCard from './components/AccessCard';
import NdtCard from './components/NdtCard';
import RevisionCard from './components/RevisionCard';
import CustomTextCard from './components/CustomTextCard';
import FabricationInfo from './components/infotabs/FabricationInfo';
import EmptyInfo from './components/infotabs/EmptyInfo';
import AccessInfo from './components/infotabs/AccessInfo';
import BearingInfo from './components/infotabs/BearingInfo';
import BoltInfo from './components/infotabs/BoltInfo';
import DesignInfo from './components/infotabs/DesignInfo';
import NDTInfo from './components/infotabs/NDTInfo';
import PinInfo from './components/infotabs/PinInfo';
import ProjectInfo from './components/infotabs/ProjectInfo';
import CustomInfo from './components/infotabs/CustomInfo';
import { getUserProps, Navbar } from 'twd-react-components';
import { InputParameters, InputGeneralNotes } from './types/inputParameters';
import UploadCard from './components/UploadCard';
import dayjs from 'dayjs';

// Get app version from package.json
const packageJson = require('../package.json');
const appVersion = packageJson.version;

// Styling for the Box containing the whole app.
const AppBox = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
  backgroundColor: "var(--twd_grey)",
}));

// Define a type for the selected card
type SelectedCard = 'project' | 'design' | 'fabrication' | 'ndt' | 'bolt' | 'pin' | 'bearing' | 'access' | 'revision' | 'custom';
type InfoComponent = React.ComponentType<{ id: string }>;

function App() {
  const {profileName} = getUserProps()
  const [inputParameters, setInputParameters] = useState<InputParameters>({
    design: {
      design_temp: "-10",
      design_description: "THE MAIN FUNCTION OF THE CONSTRUCTION IS ",
      design_code: "DNV C-101"
    },
    material: {
      access_steel: "",
      access_grating: "",
      pin_material: "",
      bearing_material: ""
    },
    fabrication: {
      welding_tolerances_linear_angles: "A",
      welding_tolerances_straightness: "E",
      machining_tolerances_iso_2768_1: "f",
      machining_tolerances_iso_2768_2: "H"
    },
    assembly: {
      bolts_pretension: "",
      bolts_method: ""
    },
    table1: {
      material_table: []
    },
    table2: {
      ndt_b_test_mpi: 0,
      ndt_b_test_ut_rt: 0,
      ndt_percentage: 20
    },
    titleBlock: {
      revisions: [{
        date: dayjs().format(),
        author: "",
        pr: "",
        app: "",
      },],
      drawing_number: "-D-000",
      title: "GENERAL NOTES",
      project_name: "",
      client: {
        logo: "",
        name: "",
        address: "",
        postalCode: "",
        country: "",
        phoneNumber: "",
        email: "",
        website: ""
      }
    },
    customText: {
      custom_texts: []
    },
    fileInfo: {
      target_dir: "",
      logo_dir: "",
      filename: "",
      export_date: dayjs().format(),
      export_author: profileName,
    }
  });

  useEffect(() => {
  }, [inputParameters]); // Triggered whenever inputParameters changes
  
  const [inputGeneralNotes, setInputGeneralNotes] = useState<InputGeneralNotes>({
    revisions: [inputParameters], // Initial revision
    app_version: appVersion,
  });

  const [selectedCard, setSelectedCard] = useState<SelectedCard | null>(null);
  const [infoData, setInfoData] = useState<Record<string, InfoComponent>>({});

  // Handle card click (toggle selection and update info)
  const handleCardClick = (cardName: SelectedCard) => {
    setSelectedCard( cardName);
    setInfoData(getInfoDataForCard(cardName));
  };
  
  // Get the appropriate info component based on the selected card
  const getInfoDataForCard = (cardName: SelectedCard): Record<string, InfoComponent> => {
    switch (cardName) {
      case 'project': return { component: ProjectInfo };
      case 'design': return { component: DesignInfo };
      case 'fabrication': return { component: FabricationInfo };
      case 'ndt': return { component: NDTInfo };
      case 'bolt': return { component: BoltInfo };
      case 'pin': return { component: PinInfo };
      case 'bearing': return { component: BearingInfo };
      case 'access': return { component: AccessInfo };
      case 'custom': return { component: CustomInfo };
      default: return { component: EmptyInfo };
    }
  };

// Handles updating both input parameters and revisions
const handleCardDataChange = (newData: Partial<InputParameters>) => {
  console.info("New Data:", newData);

  // Update inputParameters
  setInputParameters((prevData) => {
    const updatedData = { ...prevData, ...newData }; // Merge new data with existing state
    console.info("Updated Input Parameters:", updatedData);

    // Update inputRevisions
    setInputGeneralNotes((prevGeneralNotes) => {
      const updatedRevisions = [...prevGeneralNotes.revisions];
      updatedRevisions[updatedRevisions.length - 1] = updatedData; // Update the last revision
      return { 
        revisions: updatedRevisions,
        app_version: appVersion,
       };
    });

    return updatedData; // Return the updated state
  });
};


  // Handle JSON upload to set input parameters and revisions
  const handleJSONUpload = (newData: InputGeneralNotes) => {
    setInputParameters(newData.revisions[newData.revisions.length -1]);
    setInputGeneralNotes(newData);
  };

  // Handle adding or removing revisions
  const handleRevisionChange = (action: 'add' | 'remove') => {
    setInputGeneralNotes((prevGeneralNotes) => {
      if (action === 'add') {
        const newRevision = { ...inputParameters };
        return { 
          revisions: [...prevGeneralNotes.revisions, newRevision],           
          app_version: appVersion,
        };
      } else if (action === 'remove' && prevGeneralNotes.revisions.length > 1) {
        const updatedRevisions = prevGeneralNotes.revisions.slice(0, -1);
        setInputParameters(updatedRevisions[updatedRevisions.length - 1]);
        return { 
          revisions: updatedRevisions,
          app_version: appVersion,
         };
        }
      return prevGeneralNotes;
    });
  };


  
  return (
    <ThemeProvider theme={twdTheme}>
      <AppBox className={"app"}>
        <Navbar title='General Notes'/>
        <Container maxWidth={'xl'} sx={{marginTop: '50px', height: 'calc(100% - 60px)', overflow: 'auto'}}>
          <FormControl>
            <Box pt={6} paddingTop={0} paddingLeft={15} paddingRight={15} height={"50%"}>
              <Grid container rowSpacing={1} columnSpacing={0} paddingBottom={1}>
                <Grid item xs={2.5}>
                  <UploadCard onInputChange={(data: InputGeneralNotes) => handleJSONUpload(data)} />
                  <ProjectCard onInputChange={(inputParams: InputParameters) => handleCardDataChange(inputParams)} 
                    onClick={() => handleCardClick('project')}
                    isSelected={selectedCard === 'project'}
                    inputParams={inputParameters}
                  />
                  <DesignCard onInputChange={(inputParams: InputParameters) => handleCardDataChange(inputParams)}
                    onClick={() => handleCardClick('design')}
                    isSelected={selectedCard === 'design'}
                    inputParams={inputParameters}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Container style={{ paddingTop: '16px', paddingRight: '18px', paddingLeft: '18px', marginBottom: '-10px' }}>
                    <Container style={{ maxHeight: '720px', overflow: 'auto', paddingRight: '10px', marginBottom: '-10px' }} disableGutters={true}>
                      <FabricationCard
                        onInputChange={(inputParams: InputParameters) => handleCardDataChange(inputParams)}
                        onClick={() => handleCardClick('fabrication')}
                        isSelected={selectedCard === 'fabrication'}
                        inputParams={inputParameters}
                      />                    
                      <NdtCard
                        onInputChange={(inputParams: InputParameters) => handleCardDataChange(inputParams)}
                        onClick={() => handleCardClick('ndt')}
                        isSelected={selectedCard === 'ndt'}
                        inputParams={inputParameters}
                      />
                      <BoltCard 
                        onInputChange={(inputParams: InputParameters) => handleCardDataChange(inputParams)}
                        onClick={() => handleCardClick('bolt')}
                        isSelected={selectedCard === 'bolt'}
                        inputParams={inputParameters}
                      />
                      <PinCard
                        onInputChange={(inputParams: InputParameters) => handleCardDataChange(inputParams)}
                        onClick={() => handleCardClick('pin')}
                        isSelected={selectedCard === 'pin'}
                        inputParams={inputParameters}
                      />
                      <BearingCard onInputChange={(inputParams: InputParameters) => handleCardDataChange(inputParams)}
                        onClick={() => handleCardClick('bearing')}
                        isSelected={selectedCard === 'bearing'}
                        inputParams={inputParameters}
                      />
                      <AccessCard onInputChange={(inputParams: InputParameters) => handleCardDataChange(inputParams)}
                        onClick={() => handleCardClick('access')}
                        isSelected={selectedCard === 'access'}
                        inputParams={inputParameters}
                      />
                      <CustomTextCard onInputChange={(inputParams: InputParameters) => handleCardDataChange(inputParams)}
                        onClick={() => handleCardClick('custom')}
                        isSelected={selectedCard === 'custom'}
                        inputParams={inputParameters}
                      />
                      <br />
                    </Container>
                  </Container>
                </Grid>
                <Grid item xs={4.5} pb={2}>
                  <InfoCard componentName={infoData}/>
                  <RevisionCard onInputChange={(inputParams: InputParameters) => handleCardDataChange(inputParams)}
                                inputParams={inputParameters}
                                onRevisionChange={handleRevisionChange}
                            />
                  <br />
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Box></Box>
                    </Grid>
                    <Grid item xs={'auto'}>
                      <DownloadBtn inputRevs={inputGeneralNotes} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        </Container>
      </AppBox>
    </ThemeProvider>
  );
}

export default App;
