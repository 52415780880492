import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


function AccessInfo() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const apiUrl: string | undefined = process.env.REACT_APP_API_URL;

  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Access Material
        </Typography>
        <Typography variant="body2">
        <div>
          <p>Select a category based on quality and intended use:</p>
          <ul>
            <li><strong>Secondary</strong> – Reclaimed or surplus steel often used for non-critical applications. <strong>Benefits:</strong> Cost-effective, environmentally friendly. <strong>Limitations:</strong> Variable quality, limited specifications.</li>
            <li><strong>Primary</strong> – Standard-grade steel suitable for structural and general-purpose use. <strong>Benefits:</strong> Reliable, widely available. <strong>Limitations:</strong> Moderate cost, lower customization.</li>
            <li><strong>Special</strong> – High-quality steel with specific properties tailored for demanding applications. <strong>Benefits:</strong> Superior performance, precise specifications. <strong>Limitations:</strong> Higher cost.</li>
          </ul>
        </div>
        </Typography>
        <CardMedia>
          <img
            src="AccessMaterial.png"
            alt="Bolt Pretension"
            style={{ maxWidth: '100%', cursor: 'pointer' }}
            onClick={handleOpen}
          />
        </CardMedia>
        <Typography variant="body2">
          <a href={process.env.REACT_APP_GENERAL_NOTES_WORKSHOP_SHAREPOINT} target="_blank" rel="noopener noreferrer">Learn more about materials</a>
        </Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: '90%',
              maxHeight: '90%',
              overflow: 'auto',
              borderRadius: 2,
              border: '2px solid var(--twd_aqua, aqua)',
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src="AccessMaterial.png"
              alt="Bolt Pretension"
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}


export default AccessInfo;
