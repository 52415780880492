import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function FabricationInfo() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Fabrication Tolerances
        </Typography>
        <Typography variant="body2">
          Fill in the tolerance classes for fabrication.
          <br />
          It's the project SD's responsibility to check the tolerance classes are suited for the design.
          <br />
          <br />
        </Typography>
        <CardMedia>
          <img
            src="FabTolerances.png"
            alt="Fabrication Tolerances"
            style={{ maxWidth: '100%', cursor: 'pointer' }}
            onClick={handleOpen}
          />
        </CardMedia>
        <Typography variant="body2">
          <a href={process.env.REACT_APP_TOLERANCES_SHAREPOINT} target="_blank" rel="noopener noreferrer">Learn more about fabrication tolerances</a>
        </Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: '90%',
              maxHeight: '90%',
              overflow: 'auto',
              borderRadius: 2,
              border: '2px solid var(--twd_aqua, aqua)',
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src="FabTolerances.png"
              alt="Fabrication Tolerances"
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}

export default FabricationInfo;
