import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


function ProjectInfo() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Project
        </Typography>
        <Typography variant="body2">
        <div>
          <p>Enter the project information</p>
          <ul>
            <li><strong>Drawing Number</strong> - Always has this format: TWD-NL-2025-999-D-000, by default the General notes is drawing D-000, in the case of multiple: D-001, D-002, etc.</li>
            <li><strong>Title</strong> – Title of the Drawing is GENERAL NOTES by default.</li>
            <li><strong>Project Name</strong> – Enter the project name here.</li>
            <li><strong>Client</strong> – Choose the client from the list or enter the client contact details and upload a small logo in png or jpg, between 1:1 and 21:9 aspect ratio.</li>
          </ul>
        </div>
        </Typography>
        <CardMedia>
          <img
            src="voClientblock.png"
            alt="Bolt Pretension"
            style={{ maxWidth: '100%', cursor: 'pointer'}}
            onClick={handleOpen}
          />
        </CardMedia>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: '90%',
              maxHeight: '90%',
              overflow: 'auto',
              borderRadius: 2,
              border: '2px solid var(--twd_aqua, aqua)',
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src="voClientblock.png"
              alt="Bolt Pretension"
              style={{ width: '100%', height: 'auto', border: '1px' }}
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}


export default ProjectInfo;
