import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function BearingInfo() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Bearing Material
        </Typography>
        <Typography variant="body2">
        <div>
          <p>Select a material based on strength, wear resistance, and application:</p>
          <ul>
            <li><strong>Aluminium Bronze</strong> ~250 MPa. <strong>Benefits:</strong> High strength, excellent wear resistance. <strong>Limitations:</strong> Higher cost, heavier.</li>
            <li><strong>FRLP</strong> ~200 MPa. <strong>Benefits:</strong> Lightweight, good wear resistance. <strong>Limitations:</strong> Limited load capacity.</li>
            <li><strong>UHMWPE</strong> ~40 MPa. <strong>Benefits:</strong> Good wear and impact resistance. <strong>Limitations:</strong> Very limited load capacity.</li>
            <li><strong>HDPE</strong> ~20 MPa. <strong>Benefits:</strong> Economical, corrosion-resistant. <strong>Limitations:</strong> Very limited load and temperature resistance.</li>
          </ul>
        </div>
        </Typography>
        <CardMedia>
          <img
            src="BearingMaterial.png"
            alt="Bolt Pretension"
            style={{ maxWidth: '100%', cursor: 'pointer' }}
            onClick={handleOpen}
          />
        </CardMedia>
        <Typography variant="body2">
          <a href={process.env.REACT_APP_GENERAL_NOTES_WORKSHOP_SHAREPOINT} target="_blank" rel="noopener noreferrer">Learn more about materials</a>
        </Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: '90%',
              maxHeight: '90%',
              overflow: 'auto',
              borderRadius: 2,
              border: '2px solid var(--twd_aqua, aqua)',
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src="BearingMaterial.png"
              alt="Bolt Pretension"
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}

export default BearingInfo;
