import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function EmptyInfo() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490, overflowY: 'auto' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Non-destructive Testing
        </Typography>
        <Typography variant="body2" component="div">
            Default values:
              <ul>
                <li>20% as a minimum</li>
                <li>40% for more critical designs</li>
              </ul>
            Special cases where values differ:
              <ul>
                <li>Required for the design or on client request</li>
                <li>Must align with the minimum of 20%</li>
              </ul>
        </Typography>
        <CardMedia>
          <img
            src="NdtTable.png"
            alt="NDT Table"
            style={{ maxWidth: '100%', cursor: 'pointer' }}
            onClick={handleOpen}
          />
        </CardMedia>
        <Typography variant="body2" component="div" sx={{ marginTop: 2 }}>
          <a href={process.env.REACT_APP_NDT_SHAREPOINT} target="_blank" rel="noopener noreferrer">
            Learn more about Non-destructive Testing
          </a>
        </Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: '90%',
              maxHeight: '90%',
              overflow: 'auto',
              borderRadius: 2,
              border: '2px solid var(--twd_aqua, aqua)',
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src="NdtTable.png"
              alt="NDT Table"
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}

export default EmptyInfo;
