import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { InputParameters } from '../types/inputParameters';

interface DesignCardProperties {
  onInputChange: Function;
  onClick: Function;
  isSelected: boolean;
  inputParams: Partial<InputParameters>;
}

function DesignCard(props: DesignCardProperties) {
  // State variables for design description and design temperature
  const [designTemp, setDesignTemp] = useState('');
  const [designCode, setDesignCode] = useState('');
  const [designDescription, setDesignDescription] = useState('THE MAIN FUNCTION OF THE CONSTRUCTION IS TO: ');
  const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState<Boolean>(false);

  useEffect(() => {
    document.getElementById('downloadButton')?.addEventListener('click', () => {
      setIsDownloadButtonClicked(true);
    });
  }, []);

  useEffect(() => {
    // Synchronize state with external inputParams changes
    if (props.inputParams?.design?.design_temp !== undefined) {
      setDesignTemp(props.inputParams?.design?.design_temp);
    }
    if (props.inputParams?.design?.design_code !== undefined) {
      setDesignCode(props.inputParams?.design?.design_code);
    }
    if (props.inputParams?.design?.design_description !== undefined) {
      setDesignDescription(props.inputParams?.design?.design_description);
    }
  }, [props.inputParams?.design]);

  // Handle text input changes (e.g., Design Description) for local state
  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDesignDescription(event.target.value);
  };

  // Handle button input changes (e.g., Design Temperature) for local state
  const handleBtnInputChange = (newValue: string) => {
    setDesignTemp(newValue);
  };

  // Handle select input changes (e.g., Design Code) for local state
  const handleSelectInputChange = (newValue: string) => {
    setDesignCode(newValue);
  };

  // Handle blur (on finish editing) to trigger parent state update
  const handleBlurTextInput = () => {
    props.onInputChange({
      design: {
        ...props.inputParams?.design, // Preserve existing design object
        design_description: designDescription, // Only update the design_description
      },
    });
  };

  const handleBlurBtnInput = () => {
    props.onInputChange({
      design: {
        ...props.inputParams?.design, // Preserve existing design object
        design_temp: designTemp, // Only update the design_temp
      },
    });
  };

  const handleBlurSelectInput = () => {
    props.onInputChange({
      design: {
        ...props.inputParams?.design, // Preserve existing design object
        design_code: designCode, // Only update the design_code
      },
    });
  };

  return (
    <Card
      sx={{
        marginTop: 2,
        minHeight: 300,
        boxShadow: props.isSelected
          ? 'inset 0px 0px 0px 1px var(--twd_aqua)'
          : 'none',
      }}
      onClick={(event) => props.onClick(event)}
      >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Design
        </Typography>
        <div>
          {/* Design Temperature Toggle Buttons */}
          <Typography variant="body2" pt={1} pb={1}>
            Design Temperature
          </Typography>
          <ToggleButtonGroup
            size="small"
            fullWidth
            color="primary"
            value={designTemp.toString()}
            exclusive
            // Handle changes in the ToggleButtonGroup
            onChange={(event, newValue) => {
              handleBtnInputChange(newValue);
            }}
            onBlur={handleBlurBtnInput} // Update parent state on blur
          >
            <ToggleButton value="-10">-10</ToggleButton>
            <ToggleButton value="0">0</ToggleButton>
          </ToggleButtonGroup>

          {/* Design Code Select Field */}
          <FormControl fullWidth size="small" margin="normal">
            <InputLabel id="designCode">Design Code</InputLabel>
            <Select
              value={designCode}
              label="Design code"
              onChange={(event) => handleSelectInputChange(event.target.value)}
              onBlur={handleBlurSelectInput} // Update parent state on blur
              error={isDownloadButtonClicked && designCode === ''}
            >
              <MenuItem value={'DNV C-101'}>DNV C-101</MenuItem>
              <MenuItem value={'DNV 0378'}>DNV 0378</MenuItem>
            </Select>
          </FormControl>

          {/* Design Description Text Field */}
          <TextField
            multiline
            fullWidth
            minRows={8}
            size="small"
            margin="normal"
            id="designDescription"
            label="Design Description"
            variant="outlined"
            value={designDescription}
            // Handle changes in the text field (local state only)
            onChange={handleTextInputChange}
            onBlur={handleBlurTextInput} // Update parent state on blur
            error={
              isDownloadButtonClicked &&
              (designDescription === '' ||
                designDescription === 'THE MAIN FUNCTION OF THE CONSTRUCTION IS TO: ')
            }
          />
        </div>
      </CardContent>
    </Card>
  );
}

export default DesignCard;
