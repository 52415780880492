import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { InputParameters } from '../types/inputParameters';

interface PinCardProperties {
  onInputChange: Function;
  onClick: Function;
  isSelected: boolean;
  inputParams: Partial<InputParameters>;
}
const pinMaterialsList = [
  { label: '42CrMo4' },
  { label: '34CrNiMo6' },
  { label: '30CrNiMo8' },
  { label: 'S235' },
  { label: 'S355' },
  { label: 'C2205 Duplex' },
];

function PinCard(props: PinCardProperties) {
 
  const [pinEnabled, setPinEnabled] = useState(false);

  useEffect(() => {
    // Auto-enable card if pin_material is updated in inputParams
    if (props.inputParams?.material?.pin_material) {
      setPinEnabled(true); // Enable card (set pinEnabled to false)
    }
  }, [props.inputParams?.material?.pin_material]); // Re-run when pin_material changes

  const handleCheckboxChange = () => {
    setPinEnabled((prev) => {
      const newPinEnabled = !prev; // Toggle the pinEnabled state
  
      if (!newPinEnabled) {
        // When the checkbox is unchecked (pinEnabled becomes true), reset pin_material
        props.onInputChange({
          material: {
            ...props.inputParams.material,
            pin_material: "", // Reset pin_material to an empty string
          },
        });
      }
  
      return newPinEnabled;
    });
  };

  const handleMaterialChange = (newValue: { label: string } | null) => {
    const materialUpdate = {
      material: {
        ...props.inputParams.material, // Keep other material properties
        pin_material: newValue?.label || '', // Update only pin_material
      },
    };
  
    props.onInputChange(materialUpdate); // Call the main handler
  };

  return (
    <Card
      sx={{
        marginTop: 2,
        boxShadow: props.isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none',
      }}
      onClick={(event) => props.onClick(event)}
      >
      <CardContent
        component="div"
        sx={{ paddingTop: 1, '&.MuiCardContent-root': { paddingBottom: 1 } }}
      >
        <Typography variant="h6">
          <Grid container spacing={0} alignItems={'center'}>
            <Grid sm>Pin Material</Grid>
            <Grid sm={'auto'}>
              <Checkbox checked={pinEnabled} onChange={handleCheckboxChange} />
            </Grid>
          </Grid>
        </Typography>
        {pinEnabled && (
          <Typography variant="body2">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small" margin="normal">
                <Autocomplete
                  fullWidth
                  size="small"
                  options={pinMaterialsList}
                  value={pinMaterialsList.find(
                    (option) => option.label === props.inputParams?.material?.pin_material
                  ) || null} // Set value directly from props
                  renderInput={(params) => (
                    <TextField {...params} label="Pin Material" sx={{ zIndex: 1 }} />
                  )}
                  onChange={(_, newValue) => {
                    handleMaterialChange(newValue);
                  }}
                />
              </FormControl>
            </Box>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default PinCard;
